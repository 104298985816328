<template>
  <b-nav-item-dropdown variant="link" class="location-picker-drop-down">
    <template #button-content class="test">
      <span class="current-location-wrap">
        <feather-icon icon="MapPinIcon" size="20" />
        <span
          class="ml-1 mr-1 text-body font-weight-bolder"
          v-if="currentLocation != null"
          >{{ currentLocation.name }}</span
        >
        <span class="ml-1 mr-1 text-body font-weight-bolder" v-else>{{
          T("Web.Generic.Locations.AllLocations")
        }}</span>
      </span>
      <feather-icon icon="ChevronDownIcon" class="arrow-down" />
    </template>
    <b-dropdown-group
      :header="T('Web.Generic.SwitchLocation', 'Switch location')"
      class="location-switcher"
    >
      <b-dropdown-item class="location-search" @click.native.capture.stop>
        <span class="d-flex align-items-center justify-content-between">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search')"
            v-model="search"
          />
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        :class="{ active: currentLocation == null }"
        @click="setCurrentLocation(null)"
      >
        <span class="d-flex align-items-center justify-content-between">
          {{ T("Web.Generic.Locations.AllLocations") }}
          <feather-icon v-if="currentLocation == null" icon="CheckIcon" />
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        v-for="location in filteredLocations"
        :key="location.id"
        :class="{
          active: currentLocation != null && location.id == currentLocation.id,
        }"
        @click="setCurrentLocation(location.id)"
      >
        <span class="d-flex align-items-center justify-content-between">
          {{ location.name }}
          <feather-icon
            v-if="currentLocation != null && location.id == currentLocation.id"
            icon="CheckIcon"
          />
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        class="no-items"
        @click.native.capture.stop
        v-if="filteredLocations.length == 0"
      >
        <span class="d-flex align-items-center justify-content-between">
          {{ T("Web.Generic.TableEmpty", "No items to show") }}
        </span>
      </b-dropdown-item>
    </b-dropdown-group>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownGroup,
  BImg,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      search: null,
    };
  },
  components: {
    BNavItemDropdown,
    BDropdownGroup,
    BDropdownItem,
    BImg,
  },
  computed: {
    ...mapGetters({
      locations: "locations/list",
      currentLocation: "appUser/getCurrentLocationScope",
    }),
    filteredLocations() {
      if (this.search == null || this.search == "") return this.locations;

      let results = this.locations.filter(
        (l) => l.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );

      return results;
    },
  },
  methods: {
    ...mapActions({ setCurrentLocation: "appUser/setCurrentLocationScope" }),
  },
};
</script>

<style lang="scss">
.location-picker-drop-down {
  .location-switcher {
    .location-search {
      .active {
        .dropdown-item {
          background-color: unset;
        }
      }
    }
    .no-items {
      .active {
        .dropdown-item {
          background-color: unset;
        }
      }
    }
  }
}

.location-search {
  > a {
    padding: 9px 0;
  }
  input {
    border: none;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    &:focus {
      box-shadow: 0 1px 0px 0 rgb(34 41 47 / 10%);
      border-color: #d8d6de;
      &:valid {
        box-shadow: 0 1px 0px 0 rgb(34 41 47 / 10%);
      }
    }
  }
  .dropdown-item {
    cursor: default;
    color: inherit;
    &:active {
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }
  }
}

.no-items {
  .dropdown-item {
    cursor: default;
    color: inherit;
    &:active {
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }
  }
}

.location-picker-drop-down {
  height: 100%;
  padding: 12px;
  background: #f4f4fb;
  border-radius: 4px;
  .location-switcher {
    min-width: 300px;
    .active .dropdown-item {
      background-color: rgba(64, 126, 61, 0.12);
      color: #407e3d;
    }

    ul {
      overflow-y: auto;
      max-height: 75vh;
    }
  }

  &:hover {
    background-color: rgba(64, 126, 61, 0.12);
    border-radius: 4px;
  }

  .dropdown-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      span {
        color: #407e3d;
      }
    }
  }

  min-width: 200px;
  display: flex;
}
</style>