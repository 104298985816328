<template>
  <b-nav-item
    :data-items="notifications.length"
    class="notifications_container"
    :class="{ hasNotifications: notifications.length > 0 && !shown }"
  >
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right>
        <template #button-content>
          <feather-icon @click="setShown(true)" size="21" icon="BellIcon" />
        </template>

        <span class="no_notifications_message" v-if="notifications.length == 0">
          {{ T("Web.Generic.NoNotification", "No notifications") }}</span
        >
        <span
          class="notifications_message"
          v-for="notification in notifications"
          :key="notification.id"
        >
          <b-dropdown-item link-class="d-flex ">
            <span>{{ notification.message }}</span>
            <feather-icon
              v-if="notification.id != null && notification.id != undefined"
              @click="removeNotification(notification.id)"
              size="20"
              icon="XIcon"
            />
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
        </span>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-nav-item>
</template>

<script>
import { BNavItem } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import {
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
export default {
  components: {
    BNavItem,
    BNavItemDropdown,
    BNavbarNav,
    BDropdownItem,
    BDropdownDivider,
  },
  computed: {
    ...mapGetters({
      notifications: "notification/getNotifications",
      shown: "notification/notificationsShown",
    }),
  },
  methods: {
    ...mapActions({
      removeNotification: "notification/removeNotification",
      setShown: "notification/setShown",
    }),
  },
};
</script>
<style lang="scss">
.notifications_container {
  position: relative;
  &.hasNotifications::before {
    // content: attr(data-items);
    content: " ";
    position: absolute;
    top: -5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(64, 126, 61);
    right: 11px;
  }
  .notifications_message {
    a {
      position: relative;
    }
    .feather.feather-x {
      position: absolute;
      right: 2px;
      top: 7px;
    }
  }
}
.no_notifications_message {
  text-align: center;
  width: 100%;
  display: block;
}
</style>